
* {
  padding: 0;
  margin: 0;
  outline: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  position: relative;
}

.page-wrapper {
  position: relative;
  margin-top: 68px;
}
.user-place {
  position: absolute;
  width: 250px;
  height: 200px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

#zoom {
  position: relative;
  width: 100%;
  height: 100%;
  transform-origin: 0 0;
  transform: scale(1) translate(0px, 0px);
}

div#zoom > img {
  width: 100%;
  height: auto;
}

.cursor {
  cursor: pointer;
  z-index: 1100;
}

.mark {
  position: absolute;
  width: 15px;
  height: 15px;
  background: black;
  border-radius: 50%;
  border: 1px solid #fff;
}

.interactiveBlock {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 10px 0 0 45px;
}

.interactiveBlock2 {
  display: flex;
  width: 100%;
  height: 390px;
}

.cardsBlock {
  width: 620px;
  height: 350px;
  display: flex;
}

.interactiveStreet {
  width: 24%;
  margin-right: 0.1em;
  margin-left: 0.1em;
  height: 100%;
  background-color: inherit;
  z-index: 100;
}

.interactiveStreet2 {
  width: 22%;
  margin-right: 0.4em;
  height: 50px;
  background-color: inherit;
  z-index: 100;
}

.hotel-card {
  height: 115px;
  width: 100%;
}

.factories-card {
  height: 110px;
  margin-top: 40px;
  width: 100%;
}

.quarter {
  display: flex;
  width: 305px;
  height: 270px;
  margin-right: 0.25em;
  position: relative;
}

.purpleCard {
  width: 118px;
  height: auto;
  margin-right: 6px;
}

.purpleCard2 {
  width: 118px;
  height: 81px;
  margin-bottom: 4px;
}

.yellow-card {
  width: 77px;
  margin-right: 4px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.lightForCard {
  cursor: pointer;
  box-shadow: rgb(255, 85, 85) 0 0 0 7px;
  z-index: 1000;
}

.tempHotel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1874A0;
  color: white;
}

.tempFactory {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E3A061;
  color: white;
}

.quarterCard {
  position: absolute;
  width: 14%;
  height: 62px;
  bottom: -32px;
  left: 133px;
  z-index: 150;
}

.lightingForUser {
  background-image: url("assets/img/Marker.svg");
  background-size: cover;
  background-position: center;
  cursor: pointer;
}